import React from 'react';
import b_ from 'b_';

import './index.scss';

interface Props {}

const b = b_.with('section');

export const Section: React.FC<Props> = ({ children }) => {
  return <section className={b()}>{children}</section>;
};
