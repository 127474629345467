import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../../components/Section';
import { Card } from '../../../../components/ui-kit/Card';

import './index.scss';

interface Props {}

const b = b_.with('main-page-stack');

export const Stack: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <h2>{t('My skills')}</h2>
      <p>
        {t('Frontend is written in different ways')}.
        <br />
        {t('Frontend developers have a lot of tools and approaches to development')}.
        <br />
        <br />
        {t('I use the following')}:
      </p>

      <div className={b('cards')}>
        <Card withHover>
          <h4>{t('Design')}</h4>
          <p>Figma, Zeplin</p>
        </Card>
        <Card withHover>
          <h4>{t('Layout')}</h4>
          <p>HTML, CSS, SASS, Emotion, BEM</p>
        </Card>
        <Card withHover>
          <h4>{t('Functional')}</h4>
          <p>JS, React.js, Redux, Typescript</p>
        </Card>
        <Card withHover>
          <h4>{t('Version control')}</h4>
          <p>Git</p>
        </Card>
        <Card withHover>
          <h4>{t('Bundling')}</h4>
          <p>Webpack</p>
        </Card>
        <Card>
          <p>{t('And many other technologies')}...</p>
        </Card>
      </div>
    </Section>
  );
};
