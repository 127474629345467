import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { History } from 'history';
import { i18n as I18n } from 'i18next';
import { Router } from 'react-router';

import 'styles/index.scss';

interface Props {
  children: React.ReactElement<any>;
  history: History;
  i18n: I18n;
}

const App: React.FC<Props> = ({ children, history, i18n }) => (
  <I18nextProvider i18n={i18n}>
    <Router history={history}>{children}</Router>
  </I18nextProvider>
);

export default App;
