import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import i18next from 'i18n/config';

import './index.scss';

interface Props {}

const b = b_.with('language-switcher');

export const LanguageSwitcher: React.FC<Props> = () => {
  const {
    i18n: { language, languages }
  } = useTranslation();

  const [currentLng, setCurrentLng] = React.useState(language);

  const changeLang = () => {
    const lng = languages.find(item => item !== currentLng) || 'en';

    i18next.changeLanguage(lng);
    setCurrentLng(lng);
  };

  return (
    <div onClick={() => changeLang()} className={b()}>
      {currentLng === 'ru' ? 'RU' : 'EN'}
    </div>
  );
};
