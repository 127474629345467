import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../../components/Section';
import { Socials } from '../../../../components/Socials';

import './index.scss';

interface Props {}

const b = b_.with('main-page-contacts');

export const Contacts: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <h2 id="my-contacts">{t('Contact me')}</h2>
      <div className={b('content')}>
        <h5>{t('in messengers')}</h5>
        <Socials />
        <br />
        <h5>{t('by phone')}</h5>
        <p>
          <a className={b('link')} href="tel:+79858836942">
            +7 (985) 883-69-42
          </a>
        </p>
        <br />
        <br />
        <h5>{t('by email')}</h5>
        <p>
          <a className={b('link')} href="mailto:hello@sigeev.ru">
            hello@sigeev.ru
          </a>
        </p>
      </div>
    </Section>
  );
};
