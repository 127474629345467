import React from 'react';
import b_ from 'b_';
import { Logo } from '../Logo';
import { LanguageSwitcher } from '../LanguageSwitcher';
// import { SideMenu } from '../SideMenu';
import { HeaderMenu } from './components/Menu';

import './index.scss';

interface Props {}

const b = b_.with('header');

export const Header: React.FC<Props> = () => {
  return (
    <header className={b()}>
      <Logo className={b('logo')} />
      <HeaderMenu>
        <LanguageSwitcher />
        {/* <div className={b('separator')}></div>
        <SideMenu /> */}
      </HeaderMenu>
    </header>
  );
};
