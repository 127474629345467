import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../../components/Section';
import { Label } from '../../../../components/ui-kit/Label';

import './index.scss';

interface Props {}

const b = b_.with('main-page-what-i-can');

export const WhatICan: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <h2>{t('I can')}</h2>
      <p>{t('make up websites and write a frontend for')}</p>
      <div className={b('labels')}>
        <Label text={t('landing pages')} />
        <Label text={t('services')} />
        <Label text={t('corporate sites')} />
        <Label text={t('admin panels')} />
        <Label text={t('email newsletters')} />
        <Label text={t('e-commerce stores')} />
        <Label text={t('dashboards')} />
        <Label text={t('ui-kits')} />
        <Label text={t('design systems')} />
      </div>
    </Section>
  );
};
