import React from 'react';
import b_ from 'b_';
import { useTranslation, Trans } from 'react-i18next';
import { Section } from '../../../../components/Section';
import { Factoid } from '../../../../components/ui-kit/Factoid';

import './index.scss';

interface Props {}

const b = b_.with('main-page-first-display');

export const FirstDisplay: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <h1 className={b('title', { size: 'h1' })}>
        <Trans i18nKey="Frontend for your application">
          Frontend <br /> for your application
        </Trans>
      </h1>
      <div className={b('factoids')}>
        <Factoid
          title={<>01.&nbsp;{t('Qualitatively')}</>}
          text={<>{t('To make everything work well')}</>}
        />
        <Factoid title={<>02.&nbsp;{t('Fast')}</>} text={<>{t('With work done on time')}</>} />
        <Factoid title={<>03.&nbsp;{t('Flexibly')}</>} text={<>{t('Without complicating')}</>} />
        <Factoid
          title={<>04.&nbsp;{t('Under contract')}</>}
          text={<>{t('To make everyone happy')}</>}
        />
      </div>
    </Section>
  );
};
