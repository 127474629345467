import React from 'react';
import b_ from 'b_';

import './index.scss';

interface Props {
  hovered?: boolean;
  outline?: boolean;
  withHover?: boolean;
}

const b = b_.with('card');

export const Card: React.FC<Props> = ({ children, hovered, outline, withHover }) => {
  return <div className={b({ hovered, outline, 'with-hover': withHover })}>{children}</div>;
};
