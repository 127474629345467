import React from 'react';
import b_ from 'b_';
import cn from 'classnames';

import './index.scss';

interface Props {
  text: string;
  className?: string;
}

const b = b_.with('label');

export const Label: React.FC<Props> = ({ text, className }) => {
  return <div className={cn(b(), className)}>{text}</div>;
};
