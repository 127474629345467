import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import './index.scss';

interface Props {
  className?: string;
}

const b = b_.with('logo');

export const Logo: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Link to="/" className={cn(b(), className)}>
      <div className={b('lastname')}>{t('Sigeev')}</div>
      <div className={b('firstname')}>{t('Alex')}</div>
    </Link>
  );
};
