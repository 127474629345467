import React from 'react';
import b_ from 'b_';

import './index.scss';

interface Props {}

const b = b_.with('footer');

export const Footer: React.FC<Props> = () => {
  return (
    <footer className={b()}>
      <p className={b('requisites')}>
        ИП Сигеев Алексей Алексеевич
        <br />
        <br />
        ОГРНИП: 319505300006426
        <br />
        <br />
        ИНН: 500119501015
      </p>
    </footer>
  );
};
