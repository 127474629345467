import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../../components/Section';
import { Card } from '../../../../components/ui-kit/Card';

import './index.scss';

interface Props {}

const b = b_.with('main-page-services');

export const Services: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div id="my-services">
        <h2>{t('My services')}</h2>

        <div className={b('cards')}>
          <Card hovered>
            <h4>{t('Layout and frontend')}</h4>
            <p>{t('of any complexity')}</p>
          </Card>
          <Card hovered>
            <h4>{t('Web development training')}</h4>
            <p>{t('from beginner to middle level')}</p>
          </Card>
          <Card hovered>
            <h4>{t('Work on your project')}</h4>
            <p>{t('as a frontend engineer')}</p>
          </Card>
        </div>
      </div>
    </Section>
  );
};
