import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainPage from 'pages/MainPage';

interface Props {}

export const Root: React.FC<Props> = () => {
  return (
    <Router>
      <Helmet
        title="Alex Sigeev. Frontend and layout development."
        link={[
          {
            rel: 'apple-touch-icon',
            href: require('../../static/icons/icon.png'),
            sizes: '144x144'
          },
          {
            rel: 'apple-touch-icon',
            href: require('../../static/icons/icon-big.png'),
            sizes: '180x180'
          },
          {
            rel: 'icon',
            href: require('../../static/icons/favicon-16x16.png'),
            sizes: '16x16'
          },
          {
            rel: 'icon',
            href: require('../../static/icons/favicon-32x32.png'),
            sizes: '32x32'
          },
          {
            rel: 'icon',
            href: require('../../static/icons/favicon-64x64.png'),
            sizes: '64x64'
          },
          {
            rel: 'manifest',
            href: 'web-app-manifest.json'
          }
        ]}
      >
        <meta name="description" content="Frontend and layout development for your website" />
        <meta property="og:image" content="https://hellomadly.ru/im.jpg" />
      </Helmet>
      <Switch>
        <Route path="/" exact component={MainPage} />
      </Switch>
    </Router>
  );
};
