import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../../components/Section';

import './index.scss';

import me from './img/me.jpg';

interface Props {}

const b = b_.with('main-page-about');

export const About: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className={b()}>
        <img className={b('img')} src={me} alt="" />
        <div className={b('content')}>
          <h2 className={b('title')}>{t('Hi')}!</h2>
          <p>
            {t('I am Alex')}.
            <br />
            <br />
            {t('I am frontend engineer')}.
            <br />
            <br />
            {t(
              'My goal is to create websites and web applications that work correctly and quickly'
            )}
            .
          </p>
        </div>
      </div>
    </Section>
  );
};
