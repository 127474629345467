import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Socials } from '../../../Socials';

import './index.scss';

interface Props {}

const b = b_.with('side-menu-contacts');

export const Contacts: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <ul className={b('list')}>
        <li className={b('item')}>
          <span className={b('item-title')}>{t('Phone')}</span>
          <a href="tel:+79858836942" className={b('item-contact')}>
            +7 (985) 883-69-42
          </a>
        </li>
        <li className={b('item')}>
          <span className={b('item-title')}>{t('Email')}</span>
          <a href="mailto:hello@sigeev.ru" className={b('item-contact')}>
            hello@sigeev.ru
          </a>
        </li>
        <li className={b('item')}>
          <span className={b('item-title')}>{t('Messengers')}</span>
          <div className={b('item-contact')}>
            <Socials theme="light" />
          </div>
        </li>
      </ul>
    </div>
  );
};
