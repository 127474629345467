import React from 'react';
import b_ from 'b_';
import { Logo } from '../Logo';
import { Contacts } from './components/Contacts';

import './index.scss';

interface Props {}

const b = b_.with('sidebar');

export const Sidebar: React.FC<Props> = () => {
  return (
    <aside className={b()}>
      <Logo />
      <Contacts />
    </aside>
  );
};
