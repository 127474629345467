import React from 'react';
import b_ from 'b_';

import './index.scss';

interface Props {}

const b = b_.with('header-menu');

export const HeaderMenu: React.FC<Props> = ({ children }) => {
  return <div className={b()}>{children}</div>;
};
