import React from 'react';
import b_ from 'b_';
import { TelegramIcon, WhatsappIcon } from '../IconsComponents';

import './index.scss';

interface Props {
  theme?: 'light' | 'dark';
}

const b = b_.with('socials');

export const Socials: React.FC<Props> = ({ theme = 'dark' }) => {
  return (
    <div className={b()}>
      <a href="https://t.me/alsigeev" target="_blank" rel="noopener" className={b('item')}>
        <TelegramIcon className={b('item-icon', { theme })} />
      </a>
      <a href="https://wa.me/+79858836942" target="_blank" rel="noopener" className={b('item')}>
        <WhatsappIcon className={b('item-icon', { theme })} />
      </a>
    </div>
  );
};
