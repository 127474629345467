import React from 'react';
import b_ from 'b_';

import './index.scss';

interface Props {
  title: React.ReactNode;
  text: React.ReactNode;
}

const b = b_.with('factoid');

export const Factoid: React.FC<Props> = ({ title, text }) => {
  return (
    <div className={b()}>
      <h3 className={b('title')}>{title}</h3>
      <p className={b('text')}>{text}</p>
    </div>
  );
};
