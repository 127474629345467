import React from 'react';
import { Layout } from '../../components/Layout';
import { FirstDisplay } from './components/FirstDisplay';
import { About } from './components/About';
import { WhatICan } from './components/WhatICan';
import { Services } from './components/MyServices';
import { Stack } from './components/MyStack';
import { Experience } from './components/Experience';
// import { Reviews } from './components/Reviews';
import { Contacts } from './components/Contacts';

import './index.scss';

interface Props {}

export const MainPage: React.FC<Props> = () => {
  return (
    <Layout>
      <FirstDisplay />

      <About />

      <WhatICan />

      <Services />

      <Stack />

      <Experience />

      {/* <Reviews /> */}

      <Contacts />
    </Layout>
  );
};

export default MainPage;
