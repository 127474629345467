import React from 'react';
import b_ from 'b_';
import { useTranslation } from 'react-i18next';
import { Section } from '../../../../components/Section';
import { Card } from '../../../../components/ui-kit/Card';

import './index.scss';

interface Props {}

const b = b_.with('main-page-experience');

export const Experience: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <h2>{t('I managed to work')}</h2>

      <div className={b('cards')}>
        <Card hovered>
          <h4>{t('as a freelancer')}</h4>
          <p>
            {t(
              'with a 5 years of layout website development from scratch dozens of small projects and extensive experience'
            )}
          </p>
        </Card>
        <Card hovered>
          <h4>{t('in large IT-teams')}</h4>
          <p>{t('with a modern stack agile microservices and other chips of a modern approach')}</p>
        </Card>
      </div>
    </Section>
  );
};
