import React from 'react';
import b_ from 'b_';
import { Sidebar } from '../Sidebar';
import { Header } from '../Header';
import { Footer } from '../Footer';

import './index.scss';

interface Props {}

const b = b_.with('layout');

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className={b()}>
      <Header />
      <div className={b('content')}>{children}</div>
      <Footer />
      <Sidebar />
    </div>
  );
};
