import React from 'react';
import ReactDOM from 'react-dom';
import App from './init/app';
import i18next from './i18n/config';
import { createBrowserHistory } from 'history';
import { Root } from './components/Root';

const history = createBrowserHistory();

const root = (
  <App history={history} i18n={i18next}>
    <Root />
  </App>
);

ReactDOM.render(root, document.getElementById('app'));
