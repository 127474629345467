import React from 'react';
import cn from 'classnames';

interface Props extends React.SVGAttributes<SVGElement> {}

export const TelegramIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={cn(className)} viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6294 1.38403C23.3363 1.02551 22.8972 0.828125 22.3929 0.828125C22.1188 0.828125 21.8284 0.885803 21.5299 0.999878L1.21439 8.75494C0.136268 9.16638 -0.00893449 9.78381 0.000403879 10.1152C0.00974225 10.4467 0.189735 11.0549 1.28947 11.4048C1.29606 11.4069 1.30265 11.4089 1.30924 11.4107L5.52323 12.6166L7.80216 19.1334C8.11289 20.0218 8.81034 20.5737 9.6226 20.5737C10.1347 20.5737 10.6385 20.3591 11.0794 19.9533L13.6859 17.5533L17.4665 20.5975C17.4668 20.5978 17.4674 20.598 17.4678 20.5984L17.5036 20.6273C17.5069 20.6299 17.5104 20.6326 17.5137 20.6352C17.9339 20.9611 18.3926 21.1332 18.8407 21.1334H18.8409C19.7163 21.1334 20.4134 20.4854 20.6164 19.4827L23.9451 3.04645C24.0788 2.38672 23.9667 1.79639 23.6294 1.38403ZM6.93296 12.3876L15.063 8.23419L10.0007 13.6133C9.91776 13.7014 9.85899 13.8092 9.83006 13.9266L8.85392 17.8806L6.93296 12.3876ZM10.1267 18.9188C10.093 18.9497 10.0591 18.9774 10.0252 19.0028L10.9309 15.3348L12.5783 16.6614L10.1267 18.9188ZM22.5669 2.76721L19.2382 19.2037C19.2062 19.361 19.1038 19.727 18.8407 19.727C18.7107 19.727 18.5473 19.6561 18.3802 19.5274L14.0962 16.0781C14.0957 16.0775 14.0949 16.077 14.0942 16.0766L11.5452 14.024L18.8659 6.24512C19.1003 5.99609 19.1216 5.61469 18.9163 5.34113C18.7109 5.06757 18.3386 4.98151 18.0341 5.13715L5.99345 11.2884L1.72214 10.0663L22.0315 2.31366C22.203 2.24811 22.3228 2.23438 22.3929 2.23438C22.4359 2.23438 22.5125 2.2395 22.5409 2.27448C22.5782 2.32007 22.6258 2.47552 22.5669 2.76721Z" />
    </svg>
  );
};

export const WhatsappIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={cn(className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M20.5911 3.40887C18.3928 1.21051 15.4706 0 12.3615 0C12.3613 0 12.3605 0 12.3604 0C10.8226 0.000183105 9.3241 0.297913 7.90668 0.885132C6.48908 1.47235 5.21906 2.32141 4.13159 3.40887C1.93341 5.60706 0.722903 8.52979 0.722903 11.6385C0.722903 13.4925 1.17023 15.3349 2.01782 16.9764L0.0615262 22.5652C-0.0763522 22.9594 0.021243 23.3879 0.316775 23.6832C0.523501 23.8901 0.795596 24 1.07538 24C1.19532 24 1.31653 23.9799 1.43482 23.9385L7.02356 21.9824C8.6651 22.83 10.5075 23.2773 12.3615 23.2773C15.4702 23.2773 18.3928 22.0666 20.5911 19.8684C22.7893 17.6702 24 14.7475 24 11.6387C24 8.52979 22.7895 5.60706 20.5911 3.40887ZM19.5958 18.873C17.6635 20.8055 15.0941 21.8696 12.3615 21.8696C10.6897 21.8696 9.02893 21.4561 7.5586 20.6735C7.292 20.5318 6.97687 20.5074 6.69397 20.6065L1.61646 22.3835L3.39368 17.306C3.49274 17.0228 3.4682 16.7076 3.32648 16.4412C2.54407 14.9713 2.13043 13.3105 2.13043 11.6385C2.13043 8.90588 3.19464 6.33655 5.12696 4.40424C7.05908 2.47211 9.62805 1.4079 12.3605 1.40753H12.3615C15.0943 1.40753 17.6635 2.47174 19.5958 4.40424C21.5283 6.33655 22.5925 8.9057 22.5925 11.6385C22.5925 14.3714 21.5283 16.9407 19.5958 18.873Z" />
        <path d="M16.6861 12.678C16.1507 12.1426 15.2795 12.1426 14.7441 12.678L14.1585 13.2636C12.7045 12.4713 11.5278 11.2947 10.7354 9.84064L11.3209 9.25507C11.8565 8.71967 11.8565 7.84845 11.3209 7.31305L9.74293 5.73505C9.20753 5.19965 8.33631 5.19965 7.80091 5.73505L6.53858 6.99738C5.8155 7.72046 5.77942 8.96356 6.43696 10.4978C7.00788 11.8299 8.05268 13.294 9.37891 14.6202C10.7051 15.9465 12.1693 16.9913 13.5013 17.5622C14.2303 17.8746 14.8933 18.0304 15.4663 18.0304C16.0992 18.0304 16.6222 17.8401 17.0018 17.4606L18.2641 16.1981V16.1982C18.5236 15.9388 18.6664 15.594 18.6664 15.2272C18.6664 14.8603 18.5236 14.5155 18.2641 14.2562L16.6861 12.678ZM16.0064 16.4652C15.8001 16.6716 15.1654 16.7439 14.056 16.2686C12.8845 15.7665 11.5769 14.8275 10.3743 13.6249C9.17164 12.4222 8.23285 11.1149 7.73078 9.94336C7.25525 8.83392 7.32758 8.1991 7.53394 7.99274L8.7721 6.75458L10.3014 8.28406L9.56843 9.01721C9.23701 9.34863 9.15699 9.85126 9.36957 10.268C10.3333 12.1577 11.8415 13.6659 13.7311 14.6296C14.1481 14.8422 14.6507 14.7623 14.9821 14.4307L15.7151 13.6978L17.2446 15.2272L16.0064 16.4652Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HamburgerMenuButtonIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={cn(className)} viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="4" rx="1" />
      <rect y="10" width="25" height="4" rx="1" />
      <rect y="20" width="25" height="4" rx="1" />
    </svg>
  );
};

export const MenuButtonClose: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={cn(className)}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="28"
        height="4"
        rx="1"
        transform="matrix(0.707211 -0.707003 0.707211 0.707003 0 19.7961)"
      />
      <rect
        x="3"
        y="0.000244141"
        width="28"
        height="4"
        rx="1"
        transform="rotate(45 3 0.000244141)"
      />
    </svg>
  );
};
